import APM from '../../types/apm/APM';
import { truncate } from '../../utils/MathUtils';

type ColumnConfig = {
  label: string;
  key?: keyof APM;
  display?: (apm: APM) => any;
  className?: string;
};

const TYPE: ColumnConfig = { label: 'Type', key: 'Type' };
const REG: ColumnConfig = { label: 'Registration', key: 'Reg' };
const CLIMB_ADJUSTED: ColumnConfig = {
  label: 'CLIMB',
  display: (apm) => truncate(apm.customData?.newClimb, 1),
};
const FF_ADJUSTED: ColumnConfig = {
  label: 'F-F',
  display: (apm) => truncate(apm.customData?.newFF, 1),
};
const DRAG_ADJUSTED: ColumnConfig = {
  label: 'DRAG',
  display: (apm) => truncate(apm.customData?.newDrag, 1),
};
const PERF_ADJUSTED: ColumnConfig = {
  label: 'PERF',
  display: (apm) => truncate(apm.customData?.newPerf, 1),
};
const IDLE: ColumnConfig = {
  label: 'IDLE',
  display: (apm) => truncate(apm.Idle, 1),
};
const ISSUE_DATE: ColumnConfig = {
  label: 'Last Issue Date<br/>YYYY-MM-DD',
  display: (apm) => (apm.IssueDate === 'NULL' ? '' : apm.IssueDate),
};
const ENG_CHANGE_DATE: ColumnConfig = {
  label: 'Last Eng Change Date<br/>YYYY-MM-DD',
  display: (apm) => (apm.EngChgDate === 'NULL' ? '' : apm.EngChgDate),
};

const tableConfigTypeA: ColumnConfig[] = [
  TYPE,
  REG,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  IDLE,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfigTypeA300: ColumnConfig[] = [
  TYPE,
  REG,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfigTypeB: ColumnConfig[] = [
  TYPE,
  REG,
  CLIMB_ADJUSTED,
  FF_ADJUSTED,
  DRAG_ADJUSTED,
  PERF_ADJUSTED,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

function isFourEng(acType: string) {
  return (
    acType.startsWith('A340-313') || acType.startsWith('B747-400') || acType.startsWith('B747-8F')
  );
}

function populateCustomData(apm: APM) {
  const newFF1 = apm.EC1 === 1 ? apm.Rel85 : apm.FF1;
  const newFF2 = apm.EC2 === 1 ? apm.Rel85 : apm.FF2;
  const newFF3 = apm.EC3 === 1 ? apm.Rel85 : apm.FF3;
  const newFF4 = apm.EC4 === 1 ? apm.Rel85 : apm.FF4;
  const newFF = isFourEng(apm.Type)
    ? (apm.FF * 4 - (apm.FF1 + apm.FF2 + apm.FF3 + apm.FF4) + (newFF1 + newFF2 + newFF3 + newFF4)) /
      4
    : (apm.FF * 2 - (apm.FF1 + apm.FF2) + (newFF1 + newFF2)) / 2;

  let newClimb: number, newPerf: number, newDrag: number;
  if (apm.EC1 === 1 || apm.EC2 === 1 || apm.EC3 === 1 || apm.EC4 === 1) {
    switch (apm.Reg) {
      case 'HSH':
        newClimb = newFF + 0.5 * apm.Drag - 0.5;
        newPerf = newFF + apm.Drag - 0.5;
        break;

      case 'HSJ':
      case 'HSK':
      case 'HSL':
      case 'HSM':
      case 'HSN':
      case 'HSO':
      case 'HSP':
      case 'HSG':
      case 'HSD':
      case 'HSE':
        newClimb = newFF + 0.5 * apm.Drag - 1.0;
        newPerf = newFF + apm.Drag - 1.0;
        break;

      case 'Dummy':
        newClimb = newFF + 0.5 * apm.Drag - 1.5;
        newPerf = newFF + apm.Drag - 1.5;
        break;

      case 'HTD':
      case 'HTE':
      case 'HTF':
      case 'HTG':
      case 'HTH':
      case 'HTI':
        newClimb = newFF + 0.5 * apm.Drag - 1.0;
        newPerf = newFF + apm.Drag - 1.0;
        break;

      default:
        newPerf = apm.Perf - apm.FF + newFF;
        switch (apm.Type) {
          case 'B747-400PW':
            newClimb = newPerf - 0.5;
            break;

          case 'A330-342':
          case 'A330-343':
            newClimb = newFF + 0.5 * apm.Drag - 2.0;
            newPerf = newFF + apm.Drag - 2.0;
            break;

          default:
            newClimb = apm.Climb - apm.FF + newFF;
            break;
        }
        break;
    }
  } else {
    newPerf = apm.Perf;
    newClimb = apm.Climb;
  }

  newDrag = apm.Drag;

  apm.customData = {
    newFF,
    newFF1,
    newFF2,
    newFF3,
    newFF4,
    newClimb,
    newPerf,
    newDrag,
  };
}

function getTableConfig(acType: string) {
  if (acType.startsWith('A300')) {
    return tableConfigTypeA300;
  } else if (acType.startsWith('A')) {
    return tableConfigTypeA;
  } else if (acType.startsWith('B')) {
    return tableConfigTypeB;
  }
  console.warn('config not found for acType:', acType);
  return [];
}

export type { ColumnConfig };
export { getTableConfig, populateCustomData, isFourEng };
