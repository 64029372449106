import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useApm, useApmDetail } from '../../hooks/apm/apm';
import ApmTable from '../../components/apm/ApmTable';
import Loading from '../../components/Loading';
import ApmReferenceTable from '../../components/apm/ApmReferenceTable';
import ApmPrefTable from '../../components/apm/ApmPrefTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider';
import config from '../../config';

const Apm: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { hasSomeRole } = useUserContext();
  const pageErrorState = useState<string>('');
  const [pageError] = pageErrorState;
  const { apms, types, fetchApmData, complete: loadedApm, filterApm } = useApm(pageErrorState);
  const {
    apmReferenceInfo,
    apmPrefs,
    fetchApmDetailData,
    complete: loadedApmDetail,
  } = useApmDetail(pageErrorState);
  const [selectedType, setSelectedType] = useState<string>(searchParams.get('actype') || '');

  useEffect(() => {
    fetchApmData();
    fetchApmDetailData(selectedType);
  }, [selectedType]);

  const filteredApms = filterApm({
    type: selectedType,
    apmReferences: apmReferenceInfo?.references,
  });

  const totalAircraft = filteredApms.length + (apmReferenceInfo?.references.length || 0);

  const yellowPageHandler = () => {
    navigate(`${(window as any)._navbase}/apm/reference?actype=${selectedType}`);
  };

  return (
    <div>
      <Helmet>
        <title>{`APM Page`}</title>
        <meta name='description' content='APM Application Page' />
      </Helmet>
      <h1>Aircraft Performance Factors & Preference List</h1>
      <Loading complete={loadedApm && loadedApmDetail} error={pageError}>
        <table className='apm-filter'>
          <tbody>
            <tr>
              <td>Aircraft Type</td>
              <td>
                <select
                  name='actype'
                  defaultValue={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    navigate(`${(window as any)._navbase}/apm?actype=${e.target.value}`);
                  }}
                >
                  <option value={''}>- Please Select -</option>
                  {types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        {selectedType && <p>{`Number of aircraft: ${totalAircraft}`}</p>}

        {selectedType && (
          <p>
            Note: <br />
            1. These factors do not reflect any change due to MEL/CDL.
            <br />
            2. Factors are adjusted from data issued on "Last Issue Date", if "Last Eng Change Date"
            is displayed.
            <br />
          </p>
        )}

        {hasSomeRole(config.APM_ADMIN_ROLES) && selectedType && (
          <button
            style={{ borderRadius: '3px', borderWidth: '0.5px', fontSize: '12pt' }}
            onClick={yellowPageHandler}
          >
            Edit yellow table
          </button>
        )}

        {selectedType && apmReferenceInfo && (
          <ApmReferenceTable acType={selectedType} apmReferenceInfo={apmReferenceInfo} />
        )}

        <br />

        {selectedType && <ApmTable apms={filteredApms} acType={selectedType} />}

        <br />

        {apmPrefs.length > 0 && <ApmPrefTable apmPrefs={apmPrefs} acType={selectedType} />}
      </Loading>
    </div>
  );
};

export default Apm;
