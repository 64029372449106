import axios from 'axios';
import webConfig from '../config';
import APM, { CustomData } from '../types/apm/APM';
import APMPref from '../types/apm/APMPref';
import { APMReferenceInfo } from '../types/apm/APMReference';
const { SERVER_BASE_URL, WHITE_LIST_API } = webConfig;

export type ApmData = {
  status: string;
  types: string[];
  apm_data: APM[];
};

export type ApmDataUpdateRequest = {
  acType: string;
  reg: string;
  ec1: number;
  ec2: number;
  ec3: number;
  ec4: number;
  endDate: string;
  newData: CustomData;
};

export type ApmDataUpdateResponse = {
  status: string;
  oldApm: APM;
  newApm: APM;
  apfFilename: string;
  apfContent: string;
};

export type ApmDetailData = {
  status: string;
  reference: APMReferenceInfo;
  prefs: APMPref[];
};

export type ApmDetailUpdateRequest = {
  acType: string;
  reference?: APMReferenceInfo;
};

export const getApmDataByAxios = async (): Promise<ApmData> => {
  const url = `${SERVER_BASE_URL}/apm`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.get(url, { headers });
  return response?.data as ApmData;
};

export const postApmDataByAxios = async (
  body: ApmDataUpdateRequest
): Promise<ApmDataUpdateResponse> => {
  const url = `${SERVER_BASE_URL}/apm`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.post(url, body, { headers });
  return response?.data;
};

export const getApmDetailByAxios = async (params: { actype: string }): Promise<ApmDetailData> => {
  const url = `${SERVER_BASE_URL}/apm/detail`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.get(url, { headers, params });
  return response?.data;
};

export const postApmDetailByAxios = async (body: ApmDetailUpdateRequest): Promise<any> => {
  const url = `${SERVER_BASE_URL}/apm/detail`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.post(url, body, { headers });
  return response?.data;
};
