import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useApm, useApmDetail } from '../../hooks/apm/apm';
import Loading from '../../components/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import EngineChangeTable from '../../components/apm/EngineChangeTable';
import APM from '../../types/apm/APM';
import { ApmDataUpdateResponse } from '../../services/apm';
import EngineChangeResult from '../../components/apm/EngineChangeResult';
import moment from 'moment-timezone';

const EngineChange: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const pageErrorState = useState<string>('');
  const [pageError] = pageErrorState;
  const {
    apms,
    types,
    fetchApmData,
    complete: loadedApm,
    filterApm,
    updateApm,
    updateEngineChange,
  } = useApm(pageErrorState);
  const {
    apmReferenceInfo,
    fetchApmDetailData,
    complete: loadedApmDetail,
  } = useApmDetail(pageErrorState);
  const [selectedType, setSelectedType] = useState<string>(searchParams.get('actype') || '');
  const [apmUpdateResponse, setApmUpdateResponse] = useState<ApmDataUpdateResponse>();
  const [reload, setReload] = useState<boolean>(false);

  const getSubmitHandler = (apm: APM) => {
    return async (e: any) => {
      e.preventDefault();

      const ec1 = apm.ec1Input === undefined ? apm.EC1 : apm.ec1Input;
      const ec2 = apm.ec2Input === undefined ? apm.EC2 : apm.ec2Input;
      const ec3 = apm.ec3Input === undefined ? apm.EC3 : apm.ec3Input;
      const ec4 = apm.ec4Input === undefined ? apm.EC4 : apm.ec4Input;
      const hasEngineChange = !!ec1 || !!ec2 || !!ec3 || !!ec4;

      if (hasEngineChange) {
        if (!apm.endDate) {
          window.alert(`Please enter end date of ${apm.Reg}`);
          return;
        }

        if (!moment(apm.endDate, 'YYYY-MM-DD', true).isValid()) {
          window.alert(`End date of ${apm.Reg} should be in format of YYYY-MM-DD`);
          return;
        }

        const today = moment().zone('Asia/Hong_Kong').format('YYYY-MM-DD');
        if (apm.endDate < today) {
          window.alert(`End date of ${apm.Reg} should not be earlier than today`);
          return;
        }

        if (!window.confirm(`Confirm to **UPDATE** engine change of ${apm.Reg}?`)) return;
      } else {
        if (!window.confirm(`Confirm to **CANCEL** engine change of ${apm.Reg}?`)) return;
      }
      const response = await updateEngineChange(apm);
      setApmUpdateResponse(response);
    };
  };

  useEffect(() => {
    fetchApmData();
    fetchApmDetailData(selectedType);
    setApmUpdateResponse(undefined);
  }, [selectedType, reload]);

  const filteredApms = filterApm({
    type: selectedType,
    apmReferences: apmReferenceInfo?.references,
  });

  const totalAircraft = filteredApms.length;

  return (
    <div>
      <Helmet>
        <title>{`APM Engine Change Page`}</title>
        <meta name='description' content='APM Engine Change Page' />
      </Helmet>
      <h1>Performance Factors Update for Engine Change</h1>
      <Loading complete={loadedApm && loadedApmDetail} error={pageError}>
        <table className='apm-filter'>
          <tbody>
            <tr>
              <td>Aircraft Type</td>
              <td>
                <select
                  name='actype'
                  defaultValue={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                    navigate(
                      `${(window as any)._navbase}/apm/engine-change?actype=${e.target.value}`
                    );
                  }}
                >
                  <option value={''}>- Please Select -</option>
                  {types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        {apmUpdateResponse && (
          <>
            <EngineChangeResult {...apmUpdateResponse} />
            <button onClick={() => setReload((prev) => !prev)}>Back</button>
          </>
        )}

        {selectedType && !apmUpdateResponse && (
          <>
            <p>{`Number of aircraft: ${totalAircraft}`}</p>

            <p>
              Note: <br />
              1. These factors do not reflect any change due to MEL/CDL.
              <br />
              2. Factors are adjusted from data issued on "Last Issue Date", if "Last Eng Change
              Date" is displayed.
              <br />
            </p>

            <EngineChangeTable
              apms={filteredApms}
              acType={selectedType}
              updateApm={updateApm}
              getSubmitHandler={getSubmitHandler}
            />
          </>
        )}
      </Loading>
    </div>
  );
};

export default EngineChange;
