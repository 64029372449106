import APM from '../../types/apm/APM';
import { isFourEng, populateCustomData } from './apmTableConfigs';
import { getTableConfig } from './engineChangeTableConfigs';

export type EngineChangeResultProps = {
  oldApm: APM;
  newApm: APM;
  apfFilename: string;
  apfContent: string;
};

const EngineChangeResult = ({
  oldApm,
  newApm,
  apfFilename,
  apfContent,
}: EngineChangeResultProps) => {
  populateCustomData(oldApm);
  populateCustomData(newApm);
  const tableConfig = getTableConfig(oldApm.Type);
  const fourEng = isFourEng(oldApm.Type);
  return (
    <div className='engine-change-result'>
      <h2>Successfully Updated Engine Change - {oldApm.Reg}</h2>
      <p>
        APF File:{' '}
        <a
          style={{
            color: 'blue',
            textDecoration: 'underline',
          }}
          href={`data:text;base64,${encodeURIComponent(apfContent)}`}
          download={apfFilename}
        >
          {apfFilename}
        </a>
      </p>
      <table className='apm-table'>
        <thead>
          <tr>
            <th></th>
            {tableConfig.map((config, i) => (
              <th key={`apm_result_${i}`} dangerouslySetInnerHTML={{ __html: config.label }}></th>
            ))}
            <th>Eng #1 Change</th>
            <th>Eng #2 Change</th>
            {fourEng && (
              <>
                <th>Eng #3 Change</th>
                <th>Eng #4 Change</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr className='engine-change-before'>
            <td>Before</td>
            {tableConfig.map((config) => (
              <td key={config.label} className={config.className}>
                {config.display ? config.display(oldApm) : oldApm[config.key!]}
              </td>
            ))}

            <td>
              <input type='checkbox' checked={oldApm.EC1 === 1} disabled />
            </td>
            <td>
              <input type='checkbox' checked={oldApm.EC2 === 1} disabled />
            </td>
            {fourEng && (
              <>
                <td>
                  <input type='checkbox' checked={oldApm.EC3 === 1} disabled />
                </td>
                <td>
                  <input type='checkbox' checked={oldApm.EC4 === 1} disabled />
                </td>
              </>
            )}
          </tr>
          <tr className='engine-change-after'>
            <td>After</td>
            {tableConfig.map((config) => (
              <td key={config.label} className={config.className}>
                {config.display ? config.display(newApm) : newApm[config.key!]}
              </td>
            ))}

            <td>
              <input type='checkbox' checked={newApm.EC1 === 1} disabled />
            </td>
            <td>
              <input type='checkbox' checked={newApm.EC2 === 1} disabled />
            </td>
            {fourEng && (
              <>
                <td>
                  <input type='checkbox' checked={newApm.EC3 === 1} disabled />
                </td>
                <td>
                  <input type='checkbox' checked={newApm.EC4 === 1} disabled />
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EngineChangeResult;
